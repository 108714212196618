<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialogt"
        transition="dialog-bottom-transition"
        max-width="1250"
        persistent
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Nueva Transferencia</v-toolbar>
            <v-container>
              <v-progress-linear
                v-if="loading"
                :loading="loading"
                indeterminate
              ></v-progress-linear>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="4">
                    <business-field
                      :returnObj="!business"
                      :returnId="business"
                      v-model="branches"
                    ></business-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <date-field
                      label="Fecha"
                      v-model="body.movement_date"
                      :error-messages="movementDateError"
                      @clear="movementDateError = []"
                      :rules="onlyRequired"
                    ></date-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Valor"
                      v-model="body.deb"
                      :rules="valueRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <p class="text--secondary">Cuenta Origen</p>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="companyOrigin"
                      :items="branches.branch_offices"
                      item-text="second_name"
                      item-value="id"
                      label="Sucursal"
                      placeholder="Seleccione una sucursal"
                      prepend-icon="mdi-database-search"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <account-field
                      v-model="accountOriginObj"
                      :companyId="companyOrigin.id"
                      :returnObj="true"
                      :isTransfer="true"
                      :reset="isReset"
                    ></account-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-checkbox v-model="checkbox" label="Cheque"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-if="checkbox"
                      label="Cheque"
                      v-model="body.check_code"
                      :rules="checkRules"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      label="No. Transacción"
                      v-model="codeOrigin"
                      :rules="textRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <p class="text--secondary">Cuenta Destino</p>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="companyDestinity"
                      :items="branches.branch_offices"
                      item-text="second_name"
                      item-value="id"
                      label="Sucursal"
                      placeholder="Seleccione una sucursal"
                      prepend-icon="mdi-database-search"
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4">
                    <account-field
                      v-model="accountDestinityObj"
                      :companyId="companyDestinity.id"
                      :returnObj="true"
                      :isTransfer="true"
                      :reset="isReset"
                    ></account-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="No. Transacción"
                      v-model="codeDestinity"
                      :rules="textRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="resetAll(), $emit('close')"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="success"
                    @click="create()"
                    :disabled="!valid"
                    :loading="loading"
                    >Registrar</v-btn
                  >
                </v-card-actions>
              </v-form>
              <!-- save modal -->
              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog" persistent max-width="600">
                    <v-container>
                      <v-card>
                        <div class="alert">
                          <v-alert
                            dense
                            outlined
                            type="error"
                            v-if="generalError"
                          >
                            Para realizar un Traslado, debe seleccionar Cuentas
                            Bancarias distintas
                          </v-alert>
                        </div>
                        <div class="forms" v-if="showCreateOperation1">
                          <v-card-title class="text-h5">
                            Estado de la operación</v-card-title
                          >
                          <v-card-text align="center">
                            <v-btn
                              color="indigo"
                              elevation="6"
                              fab
                              :loading="loadingCreatebtn1"
                              large
                              x-large
                            >
                              <v-icon
                                class="white--text"
                                v-if="!showErrorOperation1"
                                >mdi-check-all</v-icon
                              >
                              <v-icon class="white--text" v-else
                                >mdi-close</v-icon
                              >
                            </v-btn>
                            <div class="row">
                              <p v-if="!showErrorOperation1">
                                Se ha registrado el traslado.
                              </p>
                              <p v-else class="red--text">
                                Hubo un error al intentar guardar.
                              </p>
                              <v-divider></v-divider>
                              <!-- MODAL CREANDO TRASLADO EN CUENTA DESTINO -->
                              <v-container v-if="showCreateOperation2">
                                <!-- <v-card v-if="showCreateOperation2">
                        <v-card-title class="text-h5">
                        </v-card-title>
                        <v-card-text align="center"> -->
                                <v-btn
                                  color="indigo"
                                  elevation="6"
                                  fab
                                  large
                                  x-large
                                  :loading="loadingCreatebtn2"
                                >
                                  <v-icon
                                    class="white--text"
                                    v-if="!showErrorOperation2"
                                    >mdi-check-all</v-icon
                                  >
                                  <v-icon class="white--text" v-else
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                                <v-row>
                                  <p v-if="!showErrorOperation2" align="center">
                                    Se ha registrado el traslado
                                  </p>
                                  <p v-else class="red--text">
                                    Hubo un error al intentar guardar.
                                  </p>
                                </v-row>
                                <!-- </v-card-text>
                      </v-card> -->
                              </v-container>
                              <!-- FIN MODAL DESTINO -->
                            </div>
                          </v-card-text>
                        </div>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="(dialog = false), $emit('close')"
                          >
                            Cerrar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-container>
                  </v-dialog>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    dialogt: { type: Boolean, required: true },
  },
  components: {
    BusinessField: () => import("@/components/fields/BusinessNameField"),
    AccountField: () => import("@/components/fields/AccountField"),
    DateField: () => import("@/components/fields/DateField"),
  },
  data: () => ({
    isReset: "false",
    branches: {
      branch_offices: [
        {
          id: 0,
          business_name_id: 0,
          name: "",
          second_name: "",
        },
      ],
    },
    checkbox: true,
    business: false,
    dialog: false,
    valid: false,
    loading: false,
    loadingCreatebtn1: false,
    loadingCreatebtn2: false,
    showCreateOperation1: false,
    showCreateOperation2: false,
    reason: {},
    accountOriginObj: undefined,
    accountDestinityObj: undefined,
    companyDestinity: { id: 0, business_name_id: "", name: "" },
    companyOrigin: { id: 0, business_name_id: "", name: "" },
    codeOrigin: 0,
    codeDestinity: 0,
    body: {
      account_id: 0,
      reason_id: 202,
      code: "",
      check_code: null,
      description: "",
      movement_date: "",
      deb: "",
      document: " ",
      credeb: "deb",
      tt_id: null,
      reason_detail: false,
    },
    showErrorOperation1: false,
    showErrorOperation2: false,
    generalError: "",
    messageErrors: [],
    // form rules
    // textRules: [rules.required, rules.minLenght(5)],
    textRules: [rules.required, rules.minLength(5)],
    checkRules: [rules.required, rules.minLength(1)],
    // onlyRequired: [rules.required],
    onlyRequired: [rules.required],
    // valueRules: [rules.required, rules.minValue(0.1)],
    valueRules: [rules.required, rules.minValue(0.1)],

    // //Error messages
    movementDateError: [],
  }),
  methods: {
    create() {
      this.dialog = true;
      if (this.accountOriginObj.id != this.accountDestinityObj.id) {
        this.generalError = false;
        // CREAR TRASLADO EN CUENTA ORIGEN
        this.loading = true;
        this.showCreateOperation2 = false;
        this.showCreateOperation1 = true;
        this.loadingCreatebtn1 = true;
        if (this.body.document == null) {
          this.body.document = " ";
        }
        //
        this.body.account_id = this.accountOriginObj.id;
        this.body.document =
          this.accountDestinityObj.code +
          " | " +
          this.accountDestinityObj._bank;
        if (!this.checkbox) {
          this.body.check_code = null;
          this.body.code = this.codeOrigin;
          this.body.tt_id = 102;
        } else {
          this.body.code = null;
          this.body.tt_id = 100;
        }
        this.body.credeb = "deb";
        this.body.reason_id = 202;
        this.body.description =
          "TRASLADO A " +
          this.accountDestinityObj._bank +
          " | " +
          this.accountDestinityObj.code;
        this.body.reason_detail = false;
        requests
          .post("api/accounts/movements-in/", this.body)
          .then((response) => {
            if (response.status == 201) {
              this.loadingCreatebtn1 = false;
              this.showCreateOperation2 = true;
              this.loadingCreatebtn2 = true;

              //CREAR TRASLADO EN CUENTA DESTINO
              this.body.account_id = this.accountDestinityObj.id;
              this.body.document =
                this.accountOriginObj._bank +
                " | " +
                this.accountOriginObj.code;
              this.body.code = this.codeDestinity;
              this.body.cre = this.body.deb;
              this.body.deb = undefined;
              this.body.credeb = "cre";
              this.body.reason_id = 107;
              this.body.description =
                "TRASLADO DESDE " +
                this.accountOriginObj.code +
                " | " +
                this.accountOriginObj._bank;
              this.body.check_code = null;
              this.body.tt_id = 201;
              this.body.reason_detail = false;
              requests
                .post("api/accounts/movements-in/?byBusiness=true", this.body)
                .then((response) => {
                  if (response.status == 201) {
                    this.loadingCreatebtn1 = false;
                    this.loadingCreatebtn2 = false;
                    this.codeOrigin = 0;
                    this.codeDestinity = 0;
                    this.showErrorOperation2 = "";
                    this.showErrorOperation1 = "";
                    this.resetAll();
                  } else if (response.status == 400) {
                    this.showErrorOperation2 = true;
                    this.loadingCreatebtn2 = false;
                    if (response.data.non_field_errors) {
                      this.$toasted.global.error({
                        message:
                          "Ya existe un operación registrada con este número de boleta",
                      });
                    }
                    if (response.data.movement_date != undefined) {
                      this.movementDateError = response.data.movement_date;
                    }
                    this.movementDateError = response.data.movement_date;
                    console.log(response);
                  } else {
                    this.showErrorOperation2 = true;
                    this.loadingCreatebtn2 = false;
                  }
                  this.loading = false;
                });
              // FIN CREAR TRASLADO EN CUENTA DESTINO
            } else if (response.status == 400) {
              this.showErrorOperation1 = true;
              this.loadingCreatebtn1 = false;
              this.showCreateOperation2 = false;
              if (response.data.non_field_errors) {
                this.$toasted.global.error({
                  message:
                    "Ya existe un operación registrada con este número de boleta",
                });
              }
              if (response.data.no_data) {
                this.$toasted.global.error({
                  message: response.data.no_data,
                });
              }
              if (response.data.code) {
                this.$toasted.global.error({
                  message: response.data.code,
                });
              }
              if (response.data.movement_date != undefined) {
                this.movementDateError = response.data.movement_date;
              }
              this.movementDateError = response.data.movement_date;
              console.log(response);
            } else {
              this.showErrorOperation1 = true;
              this.loadingCreatebtn1 = false;
              this.showCreateOperation2 = false;
            }
            this.loading = false;
          });
      } else {
        this.generalError = true;
      }
    },
    resetAll() {
      this.branches = {
        branch_offices: [
          {
            id: 0,
            business_name_id: 0,
            name: "",
            second_name: "",
          },
        ],
      };
      this.checkbox = false;
      this.loadingCreatebtn1 = false;
      this.loadingCreatebtn2 = false;
      this.reason = {};
      this.accountOriginObj = undefined;
      this.accountDestinityObj = undefined;
      this.companyDestinity = { id: 0, business_name_id: "", name: "" };
      this.companyOrigin = { id: 0, business_name_id: "", name: "" };
      this.codeOrigin = 0;
      this.codeDestinity = 0;
      this.body = {
        account_id: 0,
        reason_id: 202,
        code: "",
        check_code: null,
        description: "",
        movement_date: "",
        deb: "",
        document: " ",
        credeb: "deb",
        tt_id: null,
        reason_detail: false,
      };
      this.showErrorOperation1 = false;
      this.showErrorOperation2 = false;
      this.generalError = "";
      this.messageErrors = [];
      this.$refs.form.resetValidation();
    },
    isDifferent() {
      if (
        this.accountOriginObj != undefined &&
        this.accountDestinityObj != undefined
      ) {
        if (this.accountDestinityObj.id != this.accountOriginObj.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    branches: function () {
      this.isReset = this.isReset ? false : true;
    },
  },
};
</script>
